import React from 'react';
import { IconExclamationMark } from "@tabler/icons-react";

function PageNotFound() {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center p-6 bg-white shadow-lg rounded-lg max-w-md mx-auto">
                <div className="mb-4">
                    <IconExclamationMark className="w-12 h-12 text-yellow-600 mx-auto" />
                </div>
                <h1 className="text-4xl font-bold text-gray-800 mb-4">404 - Page Not Found</h1>
                <p className="text-lg text-gray-600 mb-4">
                    Oups ! La page que vous recherchez n'existe pas ou a été déplacée.
                </p>
                <a
                    href="/"
                    className="text-blue-500 hover:text-blue-700 underline"
                >
                    Retour à l'accueil
                </a>
            </div>
        </div>
    );
}

export default PageNotFound;
