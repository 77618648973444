import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import { RotatingLines } from "react-loader-spinner";
import {IconTrash, IconPencil, IconPlus, IconCheck, IconX} from '@tabler/icons-react';

function DashBoard() {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [courseToDelete, setCourseToDelete] = useState(null);
    const [loading, setLoading] = useState(true); // État pour gérer le chargement des données

    useEffect(() => {
        // Fetch courses from API
        const fetchCourses = async () => {
            setLoading(true); // Début du chargement
            try {
                const response = await fetch('https://arthurphilippe.dev/api/cours', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Failed to fetch courses:', error);
            } finally {
                setLoading(false); // Fin du chargement
            }
        };

        fetchCourses();
    }, []);

    const handleEdit = (id) => {
        navigate(`/cours/update/${id}`);
    };

    const handleDelete = (id) => {
        setCourseToDelete(id);
        setIsDeleting(true);
    };

    const confirmDelete = async () => {
        toast.promise(
            (async () => {
                const token = localStorage.getItem('token');
                const response = await fetch(`https://arthurphilippe.dev/api/cours/${courseToDelete}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.msg || 'An error occurred');
                }

                await response.json();
                setCourses(courses.filter(course => course.id !== courseToDelete));
                setIsDeleting(false);
                setCourseToDelete(null);
            })(),
            {
                loading: 'Suppression du cours...',
                success: 'Cours supprimé avec succès',
                error: (error) => `Une erreur est survenue : ${error.message}`
            }
        );
    };

    const cancelDelete = () => {
        setIsDeleting(false);
        setCourseToDelete(null);
    };

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Dashboard Admin</h1>

            <div className="mb-4">
                <button
                    onClick={() => navigate('/cours/create')}
                    className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center"
                >
                    <IconPlus className="mr-2" />
                    Créer un cours
                </button>
            </div>

            {loading ? (
                <div className="flex items-center justify-center mt-12">
                    <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        strokeColor="#017CC5"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead className="bg-gray-100 border-b">
                        <tr>
                            <th className="py-3 px-4 text-left text-gray-600">Titre</th>
                            <th className="py-3 px-4 text-left text-gray-600">Description</th>
                            <th className={"py-3 px-4 text-left text-gray-600"}>En avant ?</th>
                            <th className="py-3 px-4 text-left text-gray-600">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {courses.map(course => (
                            <tr key={course.id} className="border-b hover:bg-gray-50">
                                <td className="py-3 px-4 text-gray-800">{course.titre}</td>
                                <td className="py-3 px-4 text-gray-600">{course.description_courte}</td>
                                <td className="py-3 px-4 text-gray-600">{course.enAvant
                                    ? <IconCheck />
                                    : <IconX />
                                }</td>
                                <td className="py-3 px-4">
                                    <div className="flex space-x-2">
                                        <button
                                            onClick={() => handleEdit(course.id)}
                                            className="bg-blue-500 text-white py-1 px-3 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center"
                                        >
                                            <IconPencil className="mr-1" />
                                            Modifier
                                        </button>
                                        <button
                                            onClick={() => handleDelete(course.id)}
                                            className="bg-red-500 text-white py-1 px-3 rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center"
                                        >
                                            <IconTrash className="mr-1" />
                                            Supprimer
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Modal de confirmation de suppression */}
            {isDeleting && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
                        <h2 className="text-lg font-semibold mb-4">Confirmation de suppression</h2>
                        <p className="mb-4">Êtes-vous sûr de vouloir supprimer ce cours ?</p>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={confirmDelete}
                                className="bg-red-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center"
                            >
                                <IconTrash className="mr-1" />
                                Confirmer
                            </button>
                            <button
                                onClick={cancelDelete}
                                className="bg-gray-500 text-white py-2 px-4 rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center"
                            >
                                <IconPencil className="mr-1" />
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DashBoard;
