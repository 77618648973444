import React, { useEffect, useState } from 'react';
import { useAuth } from "../../hooks/useAuth";
import { Link } from 'react-router-dom';
import {RotatingLines} from "react-loader-spinner"; // Assurez-vous d'importer Link depuis react-router-dom

function AccountPage() {
    const { user } = useAuth();
    const [ongoingCourses, setOngoingCourses] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fonction pour récupérer les détails d'un cours
    const fetchCourseDetails = async (courseId) => {
        try {
            const response = await fetch(`https://arthurphilippe.dev/api/cours/${courseId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des détails du cours');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du cours:', error);
            return null;
        }
    };

    // Fonction pour récupérer les cours et leurs détails
    const fetchCourses = async () => {
        try {
            const response = await fetch('https://arthurphilippe.dev/api/userCours', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des cours');
            }

            const data = await response.json();

            // Séparer les cours en cours et terminés
            const ongoing = data.filter(userCours => userCours.statut === 'EnCours');
            const completed = data.filter(userCours => userCours.statut === 'Terminé');

            const ongoingWithDetails = await Promise.all(ongoing.map(async (userCours) => {
                const details = await fetchCourseDetails(userCours.id_cours);
                return { ...userCours, details };
            }));

            const completedWithDetails = await Promise.all(completed.map(async (userCours) => {
                const details = await fetchCourseDetails(userCours.id_cours);
                return { ...userCours, details };
            }));

            setOngoingCourses(ongoingWithDetails);
            setCompletedCourses(completedWithDetails);
        } catch (error) {
            console.error('Erreur lors de la récupération des cours:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchCourses();
        }
    }, [user]);

    if (loading) {
        return <div className="flex items-center justify-center mt-12">
            <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="#017CC5"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
            />
        </div>;
    }

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Mon Compte</h1>

                {user && (
                    <div className="mb-8">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Informations Personnelles</h2>
                        <p className="text-lg text-gray-600"><strong>Email :</strong> {user.email}</p>
                    </div>
                )}

                <div className="mb-8">
                    <h2 className="text-2xl font-semibold text-gray-700 mb-4">Cours commencés</h2>
                    {ongoingCourses.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {ongoingCourses.map(cours => (
                                <Link
                                    key={cours.id_cours}
                                    to={`/cours/${cours.id_cours}`}
                                    className="relative bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden block transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl"
                                >
                                    <img
                                        className="w-full h-48 object-cover rounded-t-lg"
                                        src={cours.details?.image || 'default-image-url.jpg'} // Remplacez par une image par défaut si nécessaire
                                        alt={cours.details?.titre || 'Cours Image'}
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex flex-col justify-end p-4 rounded-b-lg">
                                        <h2 className="text-2xl font-bold text-white mb-2 drop-shadow-md">
                                            {cours.details ? cours.details.titre : 'Chargement...'}
                                        </h2>
                                        <p className="text-white text-sm mb-2">
                                            {cours.details ? cours.details.description_courte : 'Chargement...'}
                                        </p>
                                        {!cours.details?.category && (
                                            <div className="absolute top-4 right-4 bg-primary text-white text-xs font-bold rounded-lg px-2 py-1 shadow-lg">
                                                {cours.details?.category || 'Inconnu'}
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <p>Aucun cours en cours actuellement.</p>
                    )}
                </div>

                <div>
                    <h2 className="text-2xl font-semibold text-gray-700 mb-4">Cours terminés</h2>
                    {completedCourses.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {completedCourses.map(cours => (
                                <Link
                                    key={cours.id_cours}
                                    to={`/cours/${cours.id_cours}`}
                                    className="relative bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden block transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl"
                                >
                                    <img
                                        className="w-full h-48 object-cover rounded-t-lg"
                                        src={cours.details?.image || 'default-image-url.jpg'} // Remplacez par une image par défaut si nécessaire
                                        alt={cours.details?.titre || 'Cours Image'}
                                    />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex flex-col justify-end p-4 rounded-b-lg">
                                        <h2 className="text-2xl font-bold text-white mb-2 drop-shadow-md">
                                            {cours.details ? cours.details.titre : 'Chargement...'}
                                        </h2>
                                        <p className="text-white text-sm mb-2">
                                            {cours.details ? cours.details.description_courte : 'Chargement...'}
                                        </p>
                                        {!cours.details?.category && (
                                            <div className="absolute top-4 right-4 bg-primary text-white text-xs font-bold rounded-lg px-2 py-1 shadow-lg">
                                                {cours.details?.category || 'Inconnu'}
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ) : (
                        <p>Aucun cours terminé actuellement.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AccountPage;
