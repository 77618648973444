import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaTiktok, FaGithub } from 'react-icons/fa';
import logo from '../assets/img/logo.png';

function Footer() {
    return (
        <footer className="bg-gray-800 text-white p-8 mt-auto relative">
            <div className="container mx-auto flex flex-wrap justify-between items-start">
                {/* Icônes des réseaux sociaux en haut à droite */}
                <div className="absolute top-4 right-8 flex space-x-4 mt-4">
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"
                       className="flex items-center justify-center w-10 h-10 bg-instagram-gradient rounded-full text-gray-400 hover:text-white transition duration-300">
                        <FaInstagram size={20}/>
                    </a>
                    <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer"
                       className="flex items-center justify-center w-10 h-10 bg-black rounded-full text-gray-400 hover:text-white transition duration-300">
                        <FaTiktok size={20} />
                    </a>
                    <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-gray-700 rounded-full text-gray-400 hover:text-white transition duration-300">
                        <FaGithub size={20} />
                    </a>
                </div>

                {/* Deux listes horizontales à gauche */}
                <div className="flex space-x-12">
                    <div>
                        <h3 className="text-lg font-bold mb-2">Nos Services</h3>
                        <ul className="space-y-1">
                            <li><Link to="/" className="hover:underline">Service 1</Link></li>
                            <li><Link to="/" className="hover:underline">Service 2</Link></li>
                            <li><Link to="/" className="hover:underline">Service 3</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Informations</h3>
                        <ul className="space-y-1">
                            <li><Link to="/" className="hover:underline">À propos</Link></li>
                            <li><Link to="/" className="hover:underline">Contact</Link></li>
                            <li><Link to="/" className="hover:underline">FAQ</Link></li>
                        </ul>
                    </div>
                </div>

                {/* Mentions légales et copyright au centre en bas */}
                <div className="flex flex-col justify-center items-center w-full md:w-auto md:flex-1 mt-8 md:mt-0 text-center space-y-4 mr-40">
                    <p className="text-sm">&copy; 2024 Code2Rue. Tous droits réservés.</p>
                    <div className="flex flex-wrap justify-center space-x-4 mb-4">
                        <Link to="/mentions-legales" className="text-sm hover:underline">Mentions légales</Link>
                        <Link to="/politique-de-confidentialite" className="text-sm hover:underline">Politique de confidentialité</Link>
                        <Link to="/conditions-utilisation" className="text-sm hover:underline">Conditions d'utilisation</Link>
                    </div>
                    <p className="text-xs italic underline">Ceci est un travail pédagogique sans objectifs commerciaux</p>
                </div>

                {/* Logo en bas à droite */}
                <div className="absolute bottom-4 right-8">
                    <img src={logo} alt="Logo" className="h-12" />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
