import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import { useAuth } from '../../hooks/useAuth';
import toast from 'react-hot-toast';
import { IconArrowRight } from '@tabler/icons-react';

const Cours = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [course, setCourse] = useState(null);
    const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [userProgress, setUserProgress] = useState({
        completedChapitres: [],
        currentChapterIndex: 0,
        courseStatus: ''
    });
    const [error, setError] = useState(null);

    // Fetch user progress
    const fetchUserProgress = async () => {
        if (!isAuthenticated) return;

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`https://arthurphilippe.dev/api/userCours/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) throw new Error('Failed to fetch user course progress');
            const data = await response.json();

            if (data) {
                setUserProgress(prevProgress => ({
                    ...prevProgress,
                    currentChapterIndex: data.currentChapterIndex ?? 0,
                    courseStatus: data.statut
                }));
            }

            const completedChaptersResponse = await fetch('https://arthurphilippe.dev/api/userChapitre', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!completedChaptersResponse.ok) throw new Error('Failed to fetch completed chapters');
            const completedChaptersData = await completedChaptersResponse.json();

            const completedChapterIds = completedChaptersData.map(userChapitre => userChapitre.id_chapitre);

            setUserProgress(prevProgress => ({
                ...prevProgress,
                completedChapitres: completedChapterIds
            }));

        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await fetch(`https://arthurphilippe.dev/api/cours/${id}`);
                if (!response.ok) throw new Error('Failed to fetch course');
                const data = await response.json();
                setCourse(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchCourse();
        if (isAuthenticated) {
            fetchUserProgress();
        } else {
            setLoading(false);
        }
    }, [id, isAuthenticated]);

    const handleCompleteChapter = async () => {
        if (!isAuthenticated) {
            toast.error('Vous devez être connecté pour compléter un chapitre.');
            return;
        }

        const token = localStorage.getItem('token');
        const currentChapterId = course?.chapitres[currentChapterIndex]?.id;

        try {
            // Mark the chapter as completed
            const chapterResponse = await fetch(`https://arthurphilippe.dev/api/userChapitre/${currentChapterId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!chapterResponse.ok) throw new Error('Échec de la mise à jour du chapitre.');

            // Check if it's the last chapter
            const isLastChapter = currentChapterIndex === (course?.chapitres.length - 1);

            if (isLastChapter) {
                // Update course status
                const courseResponse = await fetch(`https://arthurphilippe.dev/api/userCours/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!courseResponse.ok) throw new Error('Échec de la mise à jour du cours.');

                toast.success('Félicitations ! Vous avez terminé le cours.');
                navigate('/myaccount');
            } else {
                // Move to the next chapter
                const nextChapterIndex = currentChapterIndex + 1;
                setCurrentChapterIndex(nextChapterIndex);

                // Update user progress
                await fetchUserProgress();
                toast.success('Chapitre complété ! Vous êtes prêt pour le suivant.');
            }
        } catch (err) {
            toast.error(`Échec de la complétion du chapitre : ${err.message}`);
            console.error('Failed to mark chapter as complete:', err);
        }
    };

    const handleStartCourse = async () => {
        if (!isAuthenticated) return;

        const token = localStorage.getItem('token');

        try {
            await fetch(`https://arthurphilippe.dev/api/userCours`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id_cours: id })
            });

            await fetchUserProgress();
            toast.success('Le cours a bien été démarré.');
        } catch (err) {
            console.error('Failed to start the course:', err);
        }
    };

    const courseStarted = userProgress.courseStatus === 'EnCours';
    const isCourseCompleted = userProgress.courseStatus === 'Terminé';
    const chapterCompleted = Array.isArray(userProgress.completedChapitres) ? userProgress.completedChapitres.includes(course?.chapitres[currentChapterIndex]?.id) : false;

    const breadcrumbs = course?.chapitres.map((chapitre, index) => {
        const completedChapitres = Array.isArray(userProgress.completedChapitres) ? userProgress.completedChapitres : [];
        const isCompleted = completedChapitres.includes(chapitre.id);
        const isCurrent = index === currentChapterIndex;

        return (
            <li
                key={index}
                className={`flex items-center ${index < course.chapitres.length - 1 ? 'mr-2' : ''}`}
            >
                <button
                    onClick={() => setCurrentChapterIndex(index)}
                    className={`px-4 py-2 rounded-md transition-colors duration-300 ${
                        isCurrent
                            ? 'bg-blue-600 text-white font-bold'
                            : isCompleted
                                ? 'bg-green-100 text-green-600'
                                : 'bg-gray-100 text-gray-600'
                    } hover:bg-opacity-80 focus:outline-none`}
                >
                    {chapitre.titre || 'Chapitre non disponible'}
                </button>
                {index < course.chapitres.length - 1 && (
                    <IconArrowRight className="mx-2 text-gray-400" />
                )}
            </li>
        );
    });

    if (loading) {
        return (
            <div className="flex items-center justify-center mt-12">
                <RotatingLines
                    visible={true}
                    height="96"
                    width="96"
                    strokeColor="#017CC5"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                />
            </div>
        );
    }

    if (error) {
        return <div className="text-red-600 text-center">{error}</div>;
    }

    if (!course) {
        return <div className="text-center">Cours non disponible.</div>;
    }

    const currentChapter = course.chapitres[currentChapterIndex];

    return (
        <div className="container mx-auto p-6 max-w-4xl">
            <nav className="mb-6">
                <ol className="flex space-x-4 text-gray-600">
                    {breadcrumbs}
                </ol>
            </nav>
            <div className="bg-white p-8 border border-gray-200 rounded-lg shadow-lg">
                {!currentChapter && (
                    <div className="text-center">
                        <p className="text-lg text-red-600 font-semibold">Chapitre non disponible.</p>
                    </div>
                )}
                {currentChapter && (
                    <>
                        <h1 className="text-4xl font-extrabold mb-2 text-gray-900">{course.titre}</h1>
                        <p className="text-lg mb-6 text-gray-700">{course.description_longue}</p>
                        <h2 className="text-2xl font-semibold mb-4 text-gray-800">{currentChapter.titre}</h2>
                        <p className="text-lg mb-6 text-gray-700">{currentChapter.description}</p>
                        {currentChapter.sections?.map((section, sectionIndex) => (
                            <div key={sectionIndex} className="mb-8">
                                <h2 className="text-xl font-semibold mb-3 text-gray-800">{section.titre}</h2>
                                <p className="text-base mb-4 text-gray-600">{section.description}</p>
                                {section.contenus_sections.map((contenu, contenuIndex) => (
                                    <div key={contenuIndex} className="mb-6 space-y-4">
                                        {contenu.contenu_texte && (
                                            <p className="text-base text-gray-800">{contenu.contenu_texte}</p>
                                        )}

                                        {contenu.contenu_image && (
                                            <img
                                                src={contenu.contenu_image}
                                                alt="Contenu"
                                                className="w-full max-w-lg h-auto rounded-lg shadow-md mx-auto"
                                            />
                                        )}

                                        {contenu.contenu_video && (
                                            <div className="flex justify-center">
                                                <iframe
                                                    width="560"
                                                    height="315"
                                                    src={`https://www.youtube.com/embed/${contenu.contenu_video.split('v=')[1]}`}
                                                    title="YouTube video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    className="w-full max-w-lg rounded-lg shadow-md"
                                                ></iframe>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div className="flex justify-center mt-6">
                            {!isCourseCompleted ? (
                                !courseStarted ? (
                                    <button
                                        onClick={handleStartCourse}
                                        className="px-6 py-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300"
                                    >
                                        Commencer le cours
                                    </button>
                                ) : (
                                    <button
                                        onClick={handleCompleteChapter}
                                        className={`px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ${
                                            !isAuthenticated || chapterCompleted ? 'cursor-not-allowed opacity-50' : ''
                                        }`}
                                        disabled={!isAuthenticated || chapterCompleted}
                                    >
                                        {chapterCompleted ? 'Terminé' : (currentChapterIndex + 1 === course.chapitres.length ? 'Terminer le cours' : `J'ai terminé ce chapitre`)}
                                    </button>
                                )
                            ) : (
                                <button
                                    className="px-6 py-3 bg-gray-400 text-white rounded-lg shadow-md cursor-not-allowed"
                                    disabled
                                >
                                    Cours terminé
                                </button>
                            )}
                        </div>
                        {chapterCompleted && (
                            <p className="mt-4 text-green-600 font-semibold text-center">Vous avez déjà terminé ce chapitre</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Cours;