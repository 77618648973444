import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from "../../hooks/useAuth";
import { IconLock, IconMail } from '@tabler/icons-react';
import toast from "react-hot-toast";

function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const auth = useAuth();

    const onSubmit = async (data) => {
        toast.promise(auth.signIn(data.email, data.password), {
            loading: 'Connexion en cours...',
            success: 'Connexion réussie',
            error: 'Erreur lors de la connexion'
        });
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-white">
            <div className="bg-white p-8 rounded-md shadow-lg w-full max-w-md border border-gray-300">
                <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Connexion</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-6">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 flex items-center">
                            <IconMail className="mr-2 text-gray-500" />
                            Email
                        </label>
                        <input
                            id="email"
                            type="email"
                            {...register('email', {
                                required: 'L\'email est requis',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Adresse email invalide'
                                }
                            })}
                            className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        />
                        {errors.email && (
                            <p className="text-red-600 text-sm mt-1">{errors.email.message}</p>
                        )}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 flex items-center">
                            <IconLock className="mr-2 text-gray-500" />
                            Mot de passe
                        </label>
                        <input
                            id="password"
                            type="password"
                            {...register('password', {
                                required: 'Le mot de passe est requis',
                                minLength: {
                                    value: 8,
                                    message: 'Le mot de passe doit contenir au moins 8 caractères'
                                }
                            })}
                            className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        />
                        {errors.password && (
                            <p className="text-red-600 text-sm mt-1">{errors.password.message}</p>
                        )}
                    </div>

                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105"
                    >
                        Connexion
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Login;