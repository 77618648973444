import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';

const CourseCard = ({ course, category }) => {
    return (
        <Link
            to={`/cours/${course.id}`}
            className="relative bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden block transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl"
        >
            <img
                className="w-full h-48 object-cover rounded-t-lg"
                src={course.image}
                alt={course.title}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex flex-col justify-end p-4 rounded-b-lg">
                <h2 className="text-2xl font-bold text-white mb-2 drop-shadow-md">{course.titre}</h2>
                <p className="text-white text-sm mb-2">{course.description_courte}</p>
                {!category && (
                    <div className="absolute top-4 right-4 bg-primary text-white text-xs font-bold rounded-lg px-2 py-1 shadow-lg">
                        {course.category}
                    </div>
                )}
            </div>
        </Link>
    );
};

const ListCours = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true); // Début du chargement
            try {
                const url = category
                    ? `https://arthurphilippe.dev/api/cours/list/${category}`
                    : 'https://arthurphilippe.dev/api/cours';
                const response = await fetch(url);
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des cours:', error);
            } finally {
                setLoading(false); // Fin du chargement
            }
        };

        fetchCourses();
    }, [category]);

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-6">
                Liste des cours
                {category ? (
                    <span className="text-black"> : {category}</span>
                ) : null}
            </h1>
            {loading ? (
                <div className="flex items-center justify-center mt-12">
                    <RotatingLines
                        visible={true}
                        height="96"
                        width="96"
                        strokeColor="#017CC5"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                    />
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {courses.length > 0 ? (
                        courses.map((course) => (
                            <CourseCard key={course.id} course={course} category={category} />
                        ))
                    ) : (
                        <p>Aucun cours trouvé.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ListCours;
