import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoutes";
import HomePage from "./pages/user/HomePage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Register from "./pages/user/Register";
import Login from "./pages/user/Login";
import DashBoard from "./pages/admin/DashBoard";
import CoursCreate from "./pages/admin/CoursCreate";
import CoursUpdate from "./pages/admin/CoursUpdate";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import ListCours from "./pages/user/ListCours";
import Cours from "./pages/user/Cours";
import PageNotFound from "./pages/PageNotFound";
import MentionsPage from "./pages/user/MentionsPage";
import PolitiqueConfidentialitePage from "./pages/user/PolitiqueConfidentialitePage";
import ConditionsPage from "./pages/user/ConditionsPage";
import {Toaster} from "react-hot-toast";
import {AuthContextProvider} from "./context/AuthContextProvider";
import AccountPage from "./pages/user/AccountPage";


function App() {
    return (
        <div className="flex flex-col min-h-screen">
            <Router>
                <AuthContextProvider>
                    <Toaster/>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/cours" element={<ListCours />} />
                        <Route path="/cours/:id" element={<Cours />} />
                        <Route path={'/dashboard'} element={<AdminRoute><DashBoard /></AdminRoute>} />
                        <Route path={'/cours/create'} element={<AdminRoute><CoursCreate /></AdminRoute>} />
                        <Route path={'/cours/update/:id'} element={<AdminRoute><CoursUpdate /></AdminRoute>} />
                        <Route path={'/unauthorized'} element={<UnauthorizedPage />} />
                        <Route path={'/myaccount'} element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
                        <Route path="/conditions-utilisation" element={<ConditionsPage /> }/>
                        <Route path="/mentions-legales" element={<MentionsPage />} />
                        <Route path="/politique-de-confidentialite" element={<PolitiqueConfidentialitePage />} />
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="*" element={<PageNotFound />}/>
                    </Routes>
                    <Footer/>
                </AuthContextProvider>
            </Router>
        </div>
    );
}

export default App;
