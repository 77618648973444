import { NavLink, Link, useNavigate } from 'react-router-dom';
import { IconBook, IconDashboard, IconLogin, IconUserPlus, IconLogout, IconUser } from '@tabler/icons-react';
import logo from '../assets/img/logo.png';
import { useAuth } from "../hooks/useAuth";
import Select from 'react-select';

const categories = [
    { value: 'Devops', label: 'Devops' },
    { value: 'Frontend', label: 'Frontend' },
    { value: 'Backend', label: 'Backend' },
    { value: 'Fullstack', label: 'Fullstack' },
    { value: 'Mobile', label: 'Mobile' },
    { value: 'Autre', label: 'Autre' },
    { value: '', label: 'Tous les cours' }
];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#007bff' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#aaa',
    }),
};

function Header() {
    const { isAuthenticated, isAdmin, signOut } = useAuth();
    const navigate = useNavigate();

    const handleCategoryChange = (selectedOption) => {
        if (selectedOption) {
            navigate(`/cours?category=${selectedOption.value}`);
        }
    }

    return (
        <header className="sticky top-0 left-0 right-0 bg-white text-black p-4 shadow-md z-50">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                {/* Logo */}
                <div className="flex items-center mb-4 md:mb-0">
                    <Link to="/" className="text-2xl font-bold flex items-center">
                        <img src={logo} alt="Logo" className="h-12 mr-3" />
                    </Link>
                </div>

                {/* Barre de recherche avec autocomplétion */}
                <div className="flex-1 mx-4 mb-4 md:mb-0">
                    <Select
                        options={categories}
                        onChange={handleCategoryChange}
                        placeholder="Rechercher une catégorie..."
                        className="w-full"
                        classNamePrefix="react-select"
                        styles={customStyles}
                    />
                </div>

                {/* Boutons de navigation */}
                <div className="flex flex-wrap justify-center md:justify-end space-x-4">
                    <NavLink
                        to="/cours"
                        className="flex items-center px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105 mb-2 md:mb-0"
                    >
                        <IconBook className="mr-2" />
                        Cours
                    </NavLink>

                    {isAdmin ? (
                        <NavLink
                            to="/dashboard"
                            className="flex items-center px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105 mb-2 md:mb-0"
                        >
                            <IconDashboard className="mr-2" />
                            Dashboard
                        </NavLink>
                    ) : null}

                    {isAuthenticated ? (
                        <>
                            <NavLink
                                to="/myaccount"
                                className="flex items-center px-4 py-2 rounded-md bg-primary text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-transform transform hover:scale-105 mb-2 md:mb-0"
                            >
                                <IconUser className="mr-2" />
                                Mon compte
                            </NavLink>
                            <button
                                onClick={() => signOut()}
                                className="flex items-center px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition-transform transform hover:scale-105 mb-2 md:mb-0"
                            >
                                <IconLogout className="mr-2" />
                                Déconnexion
                            </button>
                        </>
                    ) : (
                        <>
                            <NavLink
                                to="/login"
                                className="flex items-center px-4 py-2 rounded-md bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition-transform transform hover:scale-105 mb-2 md:mb-0"
                            >
                                <IconLogin className="mr-2" />
                                Connexion
                            </NavLink>
                            <NavLink
                                to="/register"
                                className="flex items-center px-4 py-2 rounded-md bg-yellow-600 text-white hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-transform transform hover:scale-105 mb-2 md:mb-0"
                            >
                                <IconUserPlus className="mr-2" />
                                Inscription
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
}

export default Header;
