import React from 'react';
import { IconExclamationCircle } from "@tabler/icons-react";

function UnauthorizedPage() {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center p-6 bg-white shadow-lg rounded-lg max-w-md mx-auto">
                <div className="mb-4">
                    <IconExclamationCircle className="w-12 h-12 text-red-600 mx-auto" />
                </div>
                <h1 className="text-3xl font-bold text-red-600 mb-4">403 - Non Autorisé</h1>
                <p className="text-lg text-gray-700 mb-4">
                    Vous n'êtes pas autorisé à accéder à cette page. Seuls les administrateurs peuvent accéder à cette section.
                </p>
                <a
                    href="/"
                    className="text-blue-500 hover:text-blue-700 underline"
                >
                    Retour à l'accueil
                </a>
            </div>
        </div>
    );
}

export default UnauthorizedPage;
