import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BASE_CONTEXT = {
    isAuthenticated: false,
    isAdmin: false,
    user: null,
    signIn: () => {},
    signOut: () => {},
};

export const authContext = createContext(BASE_CONTEXT);

function AuthContext() {
    const navigate = useNavigate();
    const initialUser = localStorage.getItem('token') ? {} : null;
    const [userInSession, setUserInSession] = useState(initialUser);

    const fetchUserConnecte = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return null;
        }
        try {
            const response = await fetch('https://arthurphilippe.dev/api/user/me', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const user = await response.json();
                setUserInSession(user);
                return user;
            }
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'utilisateur connecté :', error);
        }
        return null;
    };

    useEffect(() => {
        fetchUserConnecte()
    }, []);

    const signIn = async (email, password) => {
        try {
            const response = await fetch('https://arthurphilippe.dev/api/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const result = await response.json();

            if (response.ok) {
                localStorage.setItem('token', result.token);
                await fetchUserConnecte();
                navigate('/');
            } else {
                console.error('Erreur lors de la connexion :', result.message);
                throw new Error(result.message);
            }
        } catch (error) {
            console.error('Erreur lors de la connexion :', error);
            throw error
        }
    };

    const signOut = () => {
        setUserInSession(null);
        localStorage.removeItem('token');
        navigate('/');
    };

    const context = {
        isAuthenticated: !!userInSession,
        user: userInSession,
        isAdmin: userInSession?.role === 'admin',
        signIn,
        signOut,
    };

    return context;
}

export function AuthContextProvider({ children }) {
    const context = AuthContext();

    return (
        <authContext.Provider value={context}>
            {children}
        </authContext.Provider>
    );
}
