import React from 'react';

function PolitiqueConfidentialitePage() {
    return (
        <div className="px-8 py-16 bg-gray-50">
            <h1 className="text-5xl font-extrabold text-left mb-12 text-blue-600 tracking-tight">
                Politique de Confidentialité
            </h1>
            <div className="space-y-8 text-lg text-gray-800">
                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Collecte des Informations
                    </h2>
                    <p>
                        Nous collectons des informations lorsque vous vous inscrivez sur notre site, vous connectez à votre compte, faites un achat, participez à un concours, et/ou lorsque vous vous déconnectez. Les informations collectées incluent votre nom, votre adresse e-mail, votre numéro de téléphone, et/ou votre carte de crédit.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Utilisation des Informations
                    </h2>
                    <p>
                        Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Personnaliser votre expérience et répondre à vos besoins individuels.</li>
                        <li>Améliorer notre site Web.</li>
                        <li>Améliorer le service client et vos besoins de prise en charge.</li>
                        <li>Vous contacter par e-mail.</li>
                        <li>Administrer un concours, une promotion, ou une enquête.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Confidentialité du Commerce en Ligne
                    </h2>
                    <p>
                        Nous sommes les seuls propriétaires des informations recueillies sur ce site. Vos informations personnelles ne seront pas vendues, échangées, transférées, ou données à une autre société pour n'importe quelle raison, sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande et/ou transaction, comme pour expédier une commande.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Divulgation à des Tiers
                    </h2>
                    <p>
                        Nous ne vendons, n'échangeons et ne transférons pas vos informations personnellement identifiables à des tiers. Cela n'inclut pas les tierce parties de confiance qui nous aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent de garder ces informations confidentielles.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default PolitiqueConfidentialitePage;
