import React from 'react';

function ConditionsPage() {
    return (
        <div className="px-8 py-16 bg-gray-50">
            <h1 className="text-5xl font-extrabold text-left mb-12 text-blue-600 tracking-tight">
                Conditions d'Utilisation
            </h1>
            <div className="space-y-8 text-lg text-gray-800">
                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Acceptation des Conditions
                    </h2>
                    <p>
                        En accédant et en utilisant notre site web, vous acceptez d'être lié par les présentes conditions d'utilisation, toutes les lois et réglementations applicables, et vous acceptez que vous êtes responsable de la conformité à toute loi locale applicable. Si vous n'êtes pas d'accord avec ces conditions, vous êtes interdit d'utiliser ou d'accéder à ce site.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Modification des Conditions
                    </h2>
                    <p>
                        Code2Rue se réserve le droit de réviser et de modifier les présentes conditions d'utilisation à tout moment. Les modifications prendront effet dès leur publication sur le site. Il vous incombe de consulter régulièrement ces conditions pour être informé des éventuelles modifications. Votre utilisation continue du site après la publication des modifications constitue votre acceptation des nouvelles conditions.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Utilisation du Site
                    </h2>
                    <p>
                        Vous vous engagez à utiliser ce site web uniquement à des fins légales et à ne pas causer de dommages à ce site ou à son utilisation par d'autres utilisateurs. Cela inclut, sans s'y limiter, l'interdiction de :
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Usurper l'identité de toute personne ou entité, ou déformer votre affiliation à une personne ou une entité.</li>
                        <li>Publier tout contenu illégal, diffamatoire, obscène, offensant, menaçant, ou portant atteinte à la vie privée ou aux droits de propriété intellectuelle.</li>
                        <li>Interférer ou perturber le fonctionnement du site ou les serveurs ou réseaux connectés au site.</li>
                        <li>Accéder sans autorisation aux systèmes informatiques ou réseaux liés à ce site.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Propriété Intellectuelle
                    </h2>
                    <p>
                        Tous les contenus présents sur ce site, y compris les textes, graphiques, logos, icônes, images, clips audio, vidéos, logiciels, et autres éléments, sont la propriété de Code2Rue ou de ses fournisseurs de contenu et sont protégés par les lois sur le droit d'auteur et autres droits de propriété intellectuelle. Toute utilisation non autorisée du contenu peut constituer une violation de ces lois.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Limitation de Responsabilité
                    </h2>
                    <p>
                        Code2Rue ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation ou de l'impossibilité d'utiliser le site, y compris les dommages causés par des virus ou autres composants nuisibles. Nous ne garantissons pas que le site sera ininterrompu ou exempt d'erreurs.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Droit Applicable
                    </h2>
                    <p>
                        Les présentes conditions d'utilisation sont régies et interprétées conformément aux lois en vigueur en France. Tout litige relatif à ces conditions sera soumis à la juridiction exclusive des tribunaux compétents de Paris, France.
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Contact
                    </h2>
                    <p>
                        Pour toute question concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@code2rue.com" className="text-blue-500 hover:text-blue-700">contact@code2rue.com</a>.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default ConditionsPage;
