import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {IconArrowBack, IconCirclePlus, IconDeviceFloppy} from "@tabler/icons-react";

function CoursCreate() {
    const navigate = useNavigate();
    const [titre, setTitre] = useState('');
    const [descriptionCourte, setDescriptionCourte] = useState('');
    const [descriptionLongue, setDescriptionLongue] = useState('');
    const [imageCours, setImageCours] = useState(null);
    const [enAvant, setEnAvant] = useState(false);
    const [category, setCategory] = useState('');
    const [chapitres, setChapitres] = useState([
        {
            titre: '',
            description: '',
            ordre: 1,
            sections: [
                {
                    titre: '',
                    description: '',
                    ordre: 1,
                    contenus: [
                        {
                            type_contenu: '',
                            contenu_image: null,
                            contenu_video: '',
                            contenu_texte: '',
                            ordre: 1
                        }
                    ]
                }
            ]
        }
    ]);

    const handleAddChapitre = () => {
        setChapitres([
            ...chapitres,
            {
                titre: '',
                description: '',
                ordre: chapitres.length + 1,
                sections: [
                    {
                        titre: '',
                        description: '',
                        ordre: 1,
                        contenus: [
                            {
                                type_contenu: '',
                                contenu_image: null,
                                contenu_video: '',
                                contenu_texte: '',
                                ordre: 1
                            }
                        ]
                    }
                ]
            }
        ]);
    };

    const handleRemoveChapitre = (index) => {
        const newChapitres = chapitres.filter((_, i) => i !== index);
        setChapitres(newChapitres);
        setChapitres(newChapitres.map((chapitre, i) => ({
            ...chapitre,
            ordre: i + 1
        })));
    };

    const handleChapitreChange = (index, field, value) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === index ? {...chapitre, [field]: value} : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleAddSection = (chapitreIndex) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === chapitreIndex
                ? {
                    ...chapitre,
                    sections: [
                        ...chapitre.sections,
                        {
                            titre: '',
                            description: '',
                            ordre: chapitre.sections.length + 1,
                            contenus: [
                                {
                                    type_contenu: '',
                                    contenu_image: null,
                                    contenu_video: '',
                                    contenu_texte: '',
                                    ordre: 1
                                }
                            ]
                        }
                    ]
                }
                : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleRemoveSection = (chapitreIndex, sectionIndex) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === chapitreIndex
                ? {
                    ...chapitre,
                    sections: chapitre.sections.filter((_, sIndex) => sIndex !== sectionIndex).map((section, i) => ({
                        ...section,
                        ordre: i + 1
                    }))
                }
                : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleSectionChange = (chapitreIndex, sectionIndex, field, value) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === chapitreIndex
                ? {
                    ...chapitre,
                    sections: chapitre.sections.map((section, sIndex) =>
                        sIndex === sectionIndex ? {...section, [field]: value} : section
                    )
                }
                : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleAddContenu = (chapitreIndex, sectionIndex) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === chapitreIndex
                ? {
                    ...chapitre,
                    sections: chapitre.sections.map((section, sIndex) =>
                        sIndex === sectionIndex
                            ? {
                                ...section,
                                contenus: [
                                    ...section.contenus,
                                    {
                                        type_contenu: '',
                                        contenu_image: null,
                                        contenu_video: '',
                                        contenu_texte: '',
                                        ordre: section.contenus.length + 1
                                    }
                                ]
                            }
                            : section
                    )
                }
                : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleRemoveContenu = (chapitreIndex, sectionIndex, contenuIndex) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === chapitreIndex
                ? {
                    ...chapitre,
                    sections: chapitre.sections.map((section, sIndex) =>
                        sIndex === sectionIndex
                            ? {
                                ...section,
                                contenus: section.contenus.filter((_, cIndex) => cIndex !== contenuIndex).map((contenu, i) => ({
                                    ...contenu,
                                    ordre: i + 1
                                }))
                            }
                            : section
                    )
                }
                : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleContenuChange = (chapitreIndex, sectionIndex, contenuIndex, field, value) => {
        const newChapitres = chapitres.map((chapitre, i) =>
            i === chapitreIndex
                ? {
                    ...chapitre,
                    sections: chapitre.sections.map((section, sIndex) =>
                        sIndex === sectionIndex
                            ? {
                                ...section,
                                contenus: section.contenus.map((contenu, cIndex) =>
                                    cIndex === contenuIndex ? {...contenu, [field]: value} : contenu
                                )
                            }
                            : section
                    )
                }
                : chapitre
        );
        setChapitres(newChapitres);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const coursData = {
            titre,
            description_courte: descriptionCourte,
            description_longue: descriptionLongue,
            image: imageCours,
            enAvant,
            category,
            chapitres: chapitres.map((chapitre) => ({
                titre: chapitre.titre,
                description: chapitre.description,
                ordre: chapitre.ordre,
                sections: chapitre.sections.map((section) => ({
                    titre: section.titre,
                    description: section.description,
                    ordre: section.ordre,
                    contenus: section.contenus.map((contenu) => ({
                        type_contenu: contenu.type_contenu,
                        contenu_image: contenu.contenu_image,
                        contenu_video: contenu.contenu_video,
                        contenu_texte: contenu.contenu_texte,
                        ordre: contenu.ordre
                    }))
                }))
            }))
        };

        toast.promise(
            (async () => {
                const token = localStorage.getItem('token');
                const response = await fetch('https://arthurphilippe.dev/api/cours', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(coursData)
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.msg || 'An error occurred');
                }
                
                await response.json();

                setTitre('');
                setDescriptionCourte('');
                setDescriptionLongue('');
                setImageCours(null);
                setEnAvant(false);
                setCategory('');
                setChapitres([
                    {
                        titre: '',
                        description: '',
                        ordre: 1,
                        sections: [
                            {
                                titre: '',
                                description: '',
                                ordre: 1,
                                contenus: [
                                    {
                                        type_contenu: '',
                                        contenu_image: null,
                                        contenu_video: '',
                                        contenu_texte: '',
                                        ordre: 1
                                    }
                                ]
                            }
                        ]
                    }
                ]);

                navigate('/dashboard');
            })(),
            {
                loading: 'Enregistrement du cours...',
                success: 'Cours créé avec succès !',
                error: (error) => `Une erreur est survenue : ${error.message}`
            }
        );
    };

    return (
        <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow-xl">
            <button
                onClick={() => navigate('/dashboard')}
                className="flex items-center bg-white text-primary hover:bg-primary hover:text-white py-2 px-6 rounded-md shadow-md mb-2"
            >
                <IconArrowBack className="mr-2"/>
                Retour
            </button>
            <h1 className="text-4xl font-extrabold text-gray-800 mb-8">Créer un nouveau cours</h1>
            <form onSubmit={handleSubmit} className="space-y-8">
                <div className="space-y-6">
                    <div className="space-y-4">
                        <label className="block text-lg font-semibold text-gray-700">Titre du cours</label>
                        <input
                            type="text"
                            value={titre}
                            onChange={(e) => setTitre(e.target.value)}
                            required
                            className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="space-y-4">
                        <label className="block text-lg font-semibold text-gray-700">Description courte</label>
                        <textarea
                            value={descriptionCourte}
                            onChange={(e) => setDescriptionCourte(e.target.value)}
                            required
                            rows="4"
                            className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="space-y-4">
                        <label className="block text-lg font-semibold text-gray-700">Description longue</label>
                        <textarea
                            value={descriptionLongue}
                            onChange={(e) => setDescriptionLongue(e.target.value)}
                            required
                            rows="6"
                            className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div className="space-y-4">
                        <label className="block text-lg font-semibold text-gray-700">Image du cours</label>
                        <input
                            type="url"
                            value={imageCours}
                            onChange={(e) => setImageCours(e.target.value)}
                            required
                            className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {imageCours && (
                            <img
                                src={imageCours}
                                alt="Image du cours"
                                className="mt-6 max-w-full max-h-60 object-cover rounded-lg shadow-lg"
                            />
                        )}
                    </div>

                    <div className="space-y-4">
                        <label className="block text-lg font-semibold text-gray-700">Catégorie</label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                            className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="" disabled>Sélectionnez une catégorie</option>
                            <option value="Devops">Devops</option>
                            <option value="Frontend">Frontend</option>
                            <option value="Backend">Backend</option>
                            <option value="Fullstack">Fullstack</option>
                            <option value="Mobile">Mobile</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>

                    <div className="space-y-4">
                        <label className="block text-lg font-semibold text-gray-700">Mettre en avant</label>
                        <input
                            type="checkbox"
                            checked={enAvant}
                            onChange={(e) => setEnAvant(e.target.checked)}
                            className="w-6 h-6 text-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        />
                    </div>

                </div>

                <div className="space-y-6">
                    <label className="block text-lg font-semibold text-gray-700">Chapitre</label>
                    {chapitres.map((chapitre, chapitreIndex) => (
                        <div key={chapitreIndex} className="mb-8 p-6 border border-gray-200 rounded-lg shadow-md">
                            <div className="flex items-center justify-between mb-6">
                                <h2 className="text-2xl font-semibold text-gray-800">Chapitre {chapitreIndex + 1}</h2>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveChapitre(chapitreIndex)}
                                    className="text-red-600 hover:text-red-700"
                                >
                                    Supprimer ce chapitre
                                </button>
                            </div>

                            <div className="space-y-4 mb-6">
                                <label className="block text-lg font-semibold text-gray-700">Titre du
                                    chapitre</label>
                                <input
                                    type="text"
                                    value={chapitre.titre}
                                    onChange={(e) => handleChapitreChange(chapitreIndex, 'titre', e.target.value)}
                                    required
                                    className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            <div className="space-y-4 mb-6">
                                <label className="block text-lg font-semibold text-gray-700">Description du
                                    chapitre</label>
                                <textarea
                                    value={chapitre.description}
                                    onChange={(e) => handleChapitreChange(chapitreIndex, 'description', e.target.value)}
                                    required
                                    rows="4"
                                    className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            <div className="space-y-6">
                                <label className="block text-lg font-semibold text-gray-700">Sections</label>
                                {chapitre.sections.map((section, sectionIndex) => (
                                    <div key={sectionIndex}
                                         className="mb-6 p-6 border border-gray-200 rounded-lg shadow-md">
                                        <div className="flex items-center justify-between mb-6">
                                            <h3 className="text-lg font-semibold text-gray-800">Section {sectionIndex + 1}</h3>
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveSection(chapitreIndex, sectionIndex)}
                                                className="text-red-600 hover:text-red-700"
                                            >
                                                Supprimer cette section
                                            </button>
                                        </div>

                                        <div className="space-y-4 mb-6">
                                            <label className="block text-lg font-semibold text-gray-700">Titre de la
                                                section</label>
                                            <input
                                                type="text"
                                                value={section.titre}
                                                onChange={(e) => handleSectionChange(chapitreIndex, sectionIndex, 'titre', e.target.value)}
                                                required
                                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                        </div>

                                        <div className="space-y-4 mb-6">
                                            <label className="block text-lg font-semibold text-gray-700">Description
                                                de la section</label>
                                            <textarea
                                                value={section.description}
                                                onChange={(e) => handleSectionChange(chapitreIndex, sectionIndex, 'description', e.target.value)}
                                                required
                                                rows="4"
                                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                        </div>

                                        <div className="space-y-6">
                                            <label
                                                className="block text-lg font-semibold text-gray-700">Contenus</label>
                                            {section.contenus.map((contenu, contenuIndex) => (
                                                <div key={contenuIndex}
                                                     className="mb-6 p-6 border border-gray-200 rounded-lg shadow-md">
                                                    <div className="flex items-center justify-between mb-6">
                                                        <h4 className="text-md font-semibold text-gray-800">Contenu {contenuIndex + 1}</h4>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveContenu(chapitreIndex, sectionIndex, contenuIndex)}
                                                            className="text-red-600 hover:text-red-700"
                                                        >
                                                            Supprimer ce contenu
                                                        </button>
                                                    </div>

                                                    <div className="space-y-4 mb-6">
                                                        <label
                                                            className="block text-lg font-semibold text-gray-700">Type
                                                            de contenu</label>
                                                        <select
                                                            value={contenu.type_contenu}
                                                            onChange={(e) => handleContenuChange(chapitreIndex, sectionIndex, contenuIndex, 'type_contenu', e.target.value)}
                                                            required
                                                            className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                        >
                                                            <option value="">Sélectionnez un type</option>
                                                            <option value="Texte">Texte</option>
                                                            <option value="Image">Image</option>
                                                            <option value="Vidéo">Vidéo</option>
                                                        </select>
                                                    </div>

                                                    {contenu.type_contenu === 'Texte' && (
                                                        <div className="space-y-4 mb-6">
                                                            <label
                                                                className="block text-lg font-semibold text-gray-700">Texte
                                                                du contenu</label>
                                                            <textarea
                                                                value={contenu.contenu_texte}
                                                                onChange={(e) => handleContenuChange(chapitreIndex, sectionIndex, contenuIndex, 'contenu_texte', e.target.value)}
                                                                required
                                                                rows="4"
                                                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            />
                                                        </div>
                                                    )}

                                                    {contenu.type_contenu === 'Image' && (
                                                        <div className="space-y-4 mb-6">
                                                            <label
                                                                className="block text-lg font-semibold text-gray-700">Image
                                                                du contenu</label>
                                                            <input
                                                                type="url"
                                                                value={contenu.contenu_image}
                                                                onChange={(e) => handleContenuChange(chapitreIndex, sectionIndex, contenuIndex, 'contenu_image', e.target.value)}
                                                                required
                                                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            />
                                                            {contenu.contenu_image && (
                                                                <img
                                                                    src={contenu.contenu_image}
                                                                    alt="Contenu"
                                                                    className="mt-6 w-full h-auto rounded-lg shadow-lg"
                                                                />
                                                            )}
                                                        </div>
                                                    )}

                                                    {contenu.type_contenu === 'Vidéo' && (
                                                        <div className="space-y-4 mb-6">
                                                            <label
                                                                className="block text-lg font-semibold text-gray-700">URL
                                                                de la vidéo</label>
                                                            <input
                                                                type="url"
                                                                value={contenu.contenu_video}
                                                                onChange={(e) => handleContenuChange(chapitreIndex, sectionIndex, contenuIndex, 'contenu_video', e.target.value)}
                                                                required
                                                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={() => handleAddContenu(chapitreIndex, sectionIndex)}
                                                className="flex items-center mt-4 px-4 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-transform transform hover:scale-105"
                                            >
                                                <IconCirclePlus className="mr-1 w-4 h-4"/>
                                                Ajouter un contenu
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() => handleAddSection(chapitreIndex)}
                                    className="flex items-center mt-4 px-4 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-transform transform hover:scale-105"
                                >
                                    <IconCirclePlus className="mr-1 w-4 h-4"/>
                                    Ajouter une section
                                </button>
                            </div>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddChapitre}
                        className="flex items-center mt-4 px-4 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-transform transform hover:scale-105"
                    >
                        <IconCirclePlus className="mr-1 w-4 h-4"/>
                        Ajouter un chapitre
                    </button>
                </div>

                <button
                    type="submit"
                    className="flex items-center justify-center bg-green-600 text-white hover:bg-green-700 active:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 py-2 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 mb-2"
                >
                <IconDeviceFloppy className="mr-2 w-5 h-5"/>
                    Enregistrer
                </button>
            </form>
        </div>
    )
}

export default CoursCreate;
