import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons-react";
import devopsImg from '../../assets/img/devops.jpg';
import frontendImg from '../../assets/img/frontend.jpg';
import backendImg from '../../assets/img/backend.jpg';
import fullstackImg from '../../assets/img/fullstack.jpg';
import mobileImg from '../../assets/img/mobile.jpg';
import autreImg from '../../assets/img/autre.jpg';

function HomePage() {
    const navigate = useNavigate();
    const [featuredCourses, setFeaturedCourses] = useState([]);

    useEffect(() => {
        // Récupérer les cours mis en avant
        const fetchFeaturedCourses = async () => {
            try {
                const response = await fetch('https://arthurphilippe.dev/api/cours/enAvant');
                const data = await response.json();
                setFeaturedCourses(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des cours en avant:', error);
            }
        };
        fetchFeaturedCourses();
    }, []);

    return (
        <div className="bg-white">
            {/* Section principale avec image de fond, titre, description et bouton */}
            <div className="relative h-[60vh] bg-cover bg-center bg-no-repeat"
                 style={{backgroundImage: "url('https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')"}}>
                <div
                    className="absolute inset-0 flex flex-col items-start justify-center p-8 bg-gradient-to-r from-black/60 to-transparent">
                    <h1 className="text-5xl font-extrabold text-white mb-4 ml-4 drop-shadow-md">
                        Code2Rue, la nouvelle façon d'apprendre ! ✨
                    </h1>
                    <p className="text-lg text-white mb-6 ml-4 drop-shadow-md">
                        Rejoignez-nous pour des cours interactifs et pratiques qui vous aideront à maîtriser les
                        compétences les plus recherchées.
                    </p>
                    <button
                        onClick={() => navigate('/cours')}
                        className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg flex items-center space-x-3 transition-all duration-300 transform hover:bg-blue-700 hover:-translate-y-1 shadow-lg"
                    >
                        <span>Découvrir nos cours</span>
                        <IconArrowRight className="transition-transform duration-300 transform hover:translate-x-1"/>
                    </button>
                </div>
            </div>

            {/* Section de mise en avant avec 3 cartes */}
            <section className="py-16 px-8 bg-gray-50">
                <h2 className="text-3xl font-bold text-left mb-8 ml-4 text-gray-800">
                    Les cours du moment 🎯
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {featuredCourses.slice(0, 3).map((course) => (
                        <div
                            key={course.id}
                            onClick={() => navigate(`/cours/${course.id}`)}
                            className="relative bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between transition-transform transform hover:scale-105 hover:shadow-xl cursor-pointer aspect-w-1 aspect-h-1"
                        >
                            <div className="w-full h-full mb-4">
                                <img
                                    src={course.image}
                                    alt={course.titre}
                                    className="w-full h-full object-cover rounded-lg"
                                />
                            </div>
                            <div className="flex flex-col justify-between h-full">
                                <h3 className="text-2xl font-bold mb-4 text-gray-700">{course.titre}</h3>
                                <p className="text-gray-600">{course.description_courte}</p>
                            </div>
                        </div>
                    ))}
                    {featuredCourses.length === 0 && (
                        <p className="text-center text-gray-600 col-span-3">Aucun cours mis en avant pour le moment.</p>
                    )}
                </div>
            </section>

            {/* Section avec 6 cartes de catégories */}
            <section className="py-16 px-8 bg-white">
                <h2 className="text-3xl font-bold text-left mb-8 ml-4 text-gray-800">
                    Explorez nos catégories 🚀
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 place-items-center">
                    {/* Card Catégorie 1 */}
                    <Link
                        to="/cours?category=Devops"
                        className="relative bg-gray-100 p-0 rounded-lg shadow-lg h-[350px] w-[350px] flex flex-col justify-between overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl border-2 border-gray-200"
                    >
                        <img
                            src={devopsImg}
                            alt="Devops"
                            className="w-full h-full object-cover rounded-t-lg"
                        />
                        <div
                            className="absolute inset-0 flex items-end p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
                            <h3 className="text-3xl font-semibold text-white text-center drop-shadow-md">DevOps</h3>
                        </div>
                    </Link>
                    {/* Card Catégorie 2 */}
                    <Link
                        to="/cours?category=Frontend"
                        className="relative bg-gray-100 p-0 rounded-lg shadow-lg h-[350px] w-[350px] flex flex-col justify-between overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl border-2 border-gray-200"
                    >
                        <img
                            src={frontendImg}
                            alt="Frontend"
                            className="w-full h-full object-cover rounded-t-lg"
                        />
                        <div
                            className="absolute inset-0 flex items-end p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
                            <h3 className="text-3xl font-semibold text-white text-center drop-shadow-md">FrontEnd</h3>
                        </div>
                    </Link>
                    {/* Card Catégorie 3 */}
                    <Link
                        to="/cours?category=Backend"
                        className="relative bg-gray-100 p-0 rounded-lg shadow-lg h-[350px] w-[350px] flex flex-col justify-between overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl border-2 border-gray-200"
                    >
                        <img
                            src={backendImg}
                            alt="Backend"
                            className="w-full h-full object-cover rounded-t-lg"
                        />
                        <div
                            className="absolute inset-0 flex items-end p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
                            <h3 className="text-3xl font-semibold text-white text-center drop-shadow-md">BackEnd</h3>
                        </div>
                    </Link>
                    {/* Card Catégorie 4 */}
                    <Link
                        to="/cours?category=Fullstack"
                        className="relative bg-gray-100 p-0 rounded-lg shadow-lg h-[350px] w-[350px] flex flex-col justify-between overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl border-2 border-gray-200"
                    >
                        <img
                            src={fullstackImg}
                            alt="Fullstack"
                            className="w-full h-full object-cover rounded-t-lg"
                        />
                        <div
                            className="absolute inset-0 flex items-end p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
                            <h3 className="text-3xl font-semibold text-white text-center drop-shadow-md">Fullstack</h3>
                        </div>
                    </Link>
                    {/* Card Catégorie 5 */}
                    <Link
                        to="/cours?category=Mobile"
                        className="relative bg-gray-100 p-0 rounded-lg shadow-lg h-[350px] w-[350px] flex flex-col justify-between overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl border-2 border-gray-200"
                    >
                        <img
                            src={mobileImg}
                            alt="Mobile"
                            className="w-full h-full object-cover rounded-t-lg"
                        />
                        <div
                            className="absolute inset-0 flex items-end p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
                            <h3 className="text-3xl font-semibold text-white text-center drop-shadow-md">Mobile</h3>
                        </div>
                    </Link>
                    {/* Card Catégorie 6 */}
                    <Link
                        to="/cours?category=Autre"
                        className="relative bg-gray-100 p-0 rounded-lg shadow-lg h-[350px] w-[350px] flex flex-col justify-between overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl border-2 border-gray-200"
                    >
                        <img
                            src={autreImg}
                            alt="Autre"
                            className="w-full h-full object-cover rounded-t-lg"
                        />
                        <div
                            className="absolute inset-0 flex items-end p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
                            <h3 className="text-3xl font-semibold text-white text-center drop-shadow-md">Autre</h3>
                        </div>
                    </Link>
                </div>
            </section>

            {/* Section avec chiffres et description */}
            <section className="py-16 px-8 bg-blue-600 text-white mb-10">
                <h2 className="text-3xl font-bold text-center mb-8">
                    Nos succès en chiffres 🔢
                </h2>
                <div className="flex flex-col md:flex-row justify-around items-center">
                    {/* Statistique 1 */}
                    <div className="text-center mb-8 md:mb-0">
                        <h3 className="text-5xl font-extrabold mb-2">3</h3>
                        <p className="text-xl">Années d'expérience</p>
                    </div>
                    {/* Statistique 2 */}
                    <div className="text-center mb-8 md:mb-0">
                        <h3 className="text-5xl font-extrabold mb-2">500+</h3>
                        <p className="text-xl">Étudiants formés</p>
                    </div>
                    {/* Statistique 3 */}
                    <div className="text-center">
                        <h3 className="text-5xl font-extrabold mb-2">10+</h3>
                        <p className="text-xl">Cours disponibles</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HomePage;
