import React from 'react';

function MentionsPage() {
    return (
        <div className="px-8 py-16 bg-gray-50">
            <h1 className="text-5xl font-extrabold text-left mb-12 text-blue-600 tracking-tight">
                Mentions Légales
            </h1>
            <div className="space-y-8 text-lg text-gray-800">
                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Informations Générales
                    </h2>
                    <p>
                        <strong>Nom de la société :</strong> Code2Rue
                    </p>
                    <p>
                        <strong>Siège social :</strong> 123 Rue de la Programmation, 75001 Paris, France
                    </p>
                    <p>
                        <strong>Téléphone :</strong> +33 1 23 45 67 89
                    </p>
                    <p>
                        <strong>Email :</strong> contact@code2rue.com
                    </p>
                    <p>
                        <strong>Directeur de la publication :</strong> John Doe
                    </p>
                    <p>
                        <strong>Hébergeur :</strong> WebHost Inc., 456 Avenue du Serveur, 75002 Paris, France
                    </p>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Politique de Confidentialité
                    </h2>
                    <p>
                        Nous collectons des informations lorsque vous vous inscrivez sur notre site, vous connectez à votre compte, faites un achat, participez à un concours, et/ou lorsque vous vous déconnectez. Les informations collectées incluent votre nom, votre adresse e-mail, votre numéro de téléphone, et/ou votre carte de crédit.
                    </p>
                    <p>
                        Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :
                    </p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Personnaliser votre expérience et répondre à vos besoins individuels.</li>
                        <li>Améliorer notre site Web.</li>
                        <li>Améliorer le service client et vos besoins de prise en charge.</li>
                        <li>Vous contacter par e-mail.</li>
                        <li>Administrer un concours, une promotion, ou une enquête.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">
                        Droits d'Auteur & Responsabilités
                    </h2>
                    <p>
                        Tout le contenu présent sur ce site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de Code2Rue, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
                    </p>
                    <p>
                        Code2Rue ne saurait être tenu responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site code2rue.com, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées, soit de l’apparition d’un bug ou d’une incompatibilité.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default MentionsPage;
