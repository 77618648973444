import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth} from "../hooks/useAuth";

function AdminRoute({ children }) {
    const { isAdmin, isAuthenticated} = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    try {
        if (!isAdmin) {
            return <Navigate to="/unauthorized" />;
        }

        return children;
    } catch (error) {
        console.error('Invalid token:', error);
        return <Navigate to="/login" />;
    }
}

export default AdminRoute;
